<template>
  <v-app>
  <div class="Noto">
    <v-container>
      <v-alert v-model="error" type="error" dismissible>
        {{dialogText}}
      </v-alert>
      <v-row class="mt-3">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-for="item in listitems" v-bind:key="item.dev_id">
          <v-card class="mb-2" :color="getCardColor(item.eva)">
            <v-row class="devco2">
              <v-col class="text-center" cols="2" sm="2" md="2" lg="2" xl="2">
                <span v-if="item.eva==='HIGH'">
                  <img style="width:100px; height:100px;" src="../assets/03_bad.png" />
                </span>
                <span v-else-if="item.eva==='MID'">
                  <img style="width:100px; height:100px;" src="../assets/02_normal.png" />
                </span>
                <span v-else>
                  <img style="width:100px; height:100px;" src="../assets/01_smile.png" />
                </span>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" xl="8" style="margin:10px 0px 0px 0px;">
                {{ item.dev_name }}
              </v-col>
              <v-col cols="2" sm="2" md="2" lg="2" xl="2">
                <div v-if="item.co2==='－'"></div>
                <div class="text-right" style="margin:10px 20px 0px 0px;" v-else>
                  {{ item.co2 }}
                  <div style="font-size:20px; margin:-20px -15px 0px 0px;">ppm</div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
  </v-app>
</template>

<style>
  .Noto{
    font-weight:bold;
  }
  .devco2{
    font-size:38pt;
    height:120px;
  }
</style>

<script>

export default {
  data () {
    return {
      lastdata: true,
      checkbox: true,
      timer:'',
      error:false,
      dialogText:null,
      
      sortBy:"last_update_time",
      sortDesc:true,
      hosturl: process.env.VUE_APP_APIURL,
      listitems: [],
    }
  },
  methods: {
    //評価判定
    getEvaluation:function(item, danmax, danmin, warmax, warmin) {
      var val = item.co2
      console.log(item)
      if(val === "－"){
        return 'LOW'
      } else {
        if (val >= danmax || val <= danmin) return 'HIGH'
        else if (val >= warmax || val <= warmin) return 'MID'
        else return 'LOW'
      }
    },
    
    getCardColor(val){
      if(val==="HIGH"){
        return "rgba(255, 32, 0)"
      } else if(val==="MID"){
        return "rgba(255, 255, 32)"
      } else {
        return "rgba(32, 96, 255)"
      }
    },
    
    //センサ情報取得処理
    getLastData:function(){
      console.log(this.$route.params.value)
      this.getSensorGroup(this.$route.params.value)
    },
    getLastDataMethod:function( groupid ){
      var sensorList = []

      //DynamoDB
      var apiurl = this.hosturl + "/public?sensorgroupid=" + groupid
        
      this.$axios.get(apiurl).then(res => {
        console.log(res)
        if(res['data']){
          res['data'].forEach(function ( data ) {
            //console.log(data)
            if(data['dev_id'].indexOf('evs_') != -1){
              data['dev_id'] = data['dev_id'].replace('evs_','')
            }
            if(data['last_update_time']){
              //小数切り捨て
              data['co2'] = parseInt(data['co2'], 10)
            } else {
              //最終更新日時がない（データが未送信）の場合、「－」を表示
              data['co2'] = "－"
            }
            data['eva'] = this.getEvaluation(data, data['co2_warningThresholdHigh'], data['co2_warningThresholdLow'], data['co2_cautionThresholdHigh'], data['co2_cautionThresholdLow']);
            sensorList.push(data)
          }, this);
          
          sensorList.sort(function(a, b){
            if(a.dev_id > b.dev_id){
              return -1;
            } else {
              return 1;
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.dialogText = 'センサ情報の取得に失敗しました。'
        this.error = true;
      });
      
      this.listitems = sensorList
    },
    getSensorGroup:function( groupid ){
      var apiurl = this.hosturl + "/public/" + groupid
      this.$axios.get(apiurl).then(res => {
        console.log(res['data'])
        if(res['data']){
          if(res['data'][0]['published']){
            this.getLastDataMethod(groupid)
          } else {
            this.dialogText = '非公開のセンサグループです。'
            this.error = true;
            return
          }
        } else {
          this.dialogText = '指定したセンサグループは存在していません。'
          this.error = true;
          return
        }
      })
      .catch((err) => {
        console.log(err);
        this.dialogText = 'センサグループの取得に失敗しました。'
        this.error = true;
      });
    },
    
    //timerスタート
    timerStart:function(){
      var fn = () =>this.getLastData();
      this.timer = setInterval(fn,60000);
    },
    
    //timerストップ
    timerStop:function(){
      clearInterval(this.timer)
    },
  },
  //画面表示時（部品配置後）
  mounted() {
    this.getLastData()
    this.timerStart()
  },
  beforeDestroy(){
    this.timerStop()
  }
}
</script>
